<script lang="ts" setup>
import * as Sentry from '@sentry/vue';
import { useRoute } from 'vue-router';
import { getSlug } from '../../api/slugApi';
import NavItem from './NavItem.vue';
import NavProfile from './NavProfile.vue';
import ChevronLeftIcon from '../Shared/Icon/ChevronLeftIcon.vue';
import { useConfigurationStore } from '../../stores/configuration';
import NavigationIcon from '../Shared/Icon/NavigationIcon.vue';

const configurationStore = useConfigurationStore();
const route = useRoute();
const openFeedback = () => {
  if (import.meta.env.PROD) {
    const client = Sentry.getCurrentHub().getClient<Sentry.BrowserClient>();
    const feedback = client?.getIntegration(Sentry.Feedback);

    if (feedback) {
      feedback.openDialog();
    }
  }
};
</script>
<template>
  <nav
    :class="configurationStore.leftDrawer ? ' w-72' : 'w-0 xl:w-[5rem]'"
    class="absolute shrink-0 xl:relative flex flex-col bg-white z-40 h-full justify-between border-r border-gray-333 transition-all duration-100 overflow-x-hidden"
  >
    <div>
      <div class="w-full flex items-center justify-between px-6 h-24 ">
        <button
          v-if="!configurationStore.leftDrawer"
          class="hover:text-blue-333 cursor-pointer w-full flex justify-center p-2 outline-blue-333 rounded-lg"
          @click.prevent.stop="configurationStore.toggleLeftDrawer(true)"
        >
          <NavigationIcon class="text-blue-335" />
        </button>
        <img
          v-else
          src="./../../assets/logo_fond_bleu.svg"
          alt="TéO"
          class="h-12"
        >
        <div
          v-if="configurationStore.leftDrawer"
          class="text-gray-336 hover:text-blue-333 border hover:border-blue-333 rounded-[10px] border-gray-350 cursor-pointer hidden xl:flex p-2"
          @click="configurationStore.toggleLeftDrawer(false)"
        >
          <ChevronLeftIcon class="h-5 w-5" />
        </div>
      </div>
      <div class="w-full px-3 outline-blue-333 rounded-lg">
        <a
          :href="'https://' + getSlug() + '.teo-online.net/index.php?source=neo'"
          :style=" { outlineColor: '#1D89E4', borderRadius: '0.5rem'}"
        >
          <NavItem
            title="Retour à TéO Classique"
            icon="TeoLegacyIcon"
            class="text-blue-335"
          />
        </a>
        <router-link
          v-for="category in configurationStore.categories.filter(cat => cat.label !== 'Paramètres')"
          :key="category.label"
          class="outline-blue-333 rounded-lg"
          :to="{name: category.defaultRouteName, params: {slug: getSlug()}}"
        >
          <NavItem
            :title="category.label"
            :icon="category.icon"
            :class="category.defaultRouteName === route.name ? 'bg-blue-332 text-blue-333 ' : 'text-blue-335'"
          />
        </router-link>
      </div>
    </div>
    <div class="px-4">
      <div class="py-3 border-b border-gray-333">
        <router-link
          v-for="category in configurationStore.categories.filter(cat => cat.label === 'Paramètres')"
          :key="category.label"
          class="outline-blue-333 rounded-lg"
          :to="{name: category.defaultRouteName, params: {slug: getSlug()}}"
        >
          <NavItem
            :title="category.label"
            :icon="category.icon"
            :class="['TeoformTemplateList','TeoformTemplateEdit', 'ConfigurationTeoRdv', 'ConfigurationPartnerApp', 'Configuration'].includes(route.name as string) ? ' bg-blue-332 text-blue-333  ':' text-blue-335 '"
          />
        </router-link>
      </div>
      <div class="py-3 border-b border-gray-333">
        <a
          href="https://aide.teoapp.fr"
          target="_blank"
          :style=" { outlineColor: '#1D89E4', borderRadius: '0.5rem'}"
        >
          <NavItem
            title="Centre d'aide"
            icon="HelpIcon"
          />
        </a>
        <a
          href="#"
          :style=" { outlineColor: '#1D89E4', borderRadius: '0.5rem'}"
          @click.prevent="openFeedback"
        >
          <NavItem
            title="Signaler un bug"
            icon="FeedbackIcon"
          />
        </a>
      </div>
      <NavProfile />
    </div>
  </nav>
</template>
